import React from "react";
import Layout from "../component/layout";
import WebsLogoSwiper from "../component/websLogoSwiper";
import ItExpandTeamComponent from "../component/Services/IT/ItExpandTeamComponent";
import ItStaffComponent from "../component/Services/IT/ItStaffComponent";
import TechStackComponent from "../component/Services/techStackComponent";
import CustomDevelopmentCoreIndustries from "../component/Services/CustomDevelopment/customDevelopmentCoreIndustries";
import CustomDevelopmentVideoComponent from "../component/Services/CustomDevelopment/customDevelopmentVideoComponent";
import FooterComponent from "../component/footerComponent";
// import Img from "../images/core-image-2.png";
import Icon from "../images/card-icon.png";
import ItProsConsComponent from "../component/Services/IT/ItProsConsComponent";
import ItExpectComponent from "../component/Services/IT/ItExpectComponent";
import BookCallServiceComponent from "../component/bookCallServiceComponent";
import TimelineV1Component from "../component/TimelineV1Component";
// import HomeImage from "../images/IT-home-image.png";
import ServicesHomeComponentV2 from "../component/servicesHomeComponentV2";
import AccordionComponent from "../component/accordionComponent";
import { graphql } from "gatsby";
import CardsComponent from "../component/CardsComponent";
import BorderCards from "../component/BorderCards";

const ServiceIT = (props) => {
  const {
    data: {
      wpPage: {
        companyMissionSection: {
          companyMissionTitle,
          companyMissionDescription,
          companyMissionImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: HomeImage },
            },
          },
        },
        blogAlbaniaArticle: {
          blogAlbaniaArticleTitle,
          blogAlbaniaArticleAuthor,
          blogAlbaniaArticleText,
          blogAlbaniaArticleData,
          blogAlbaniaArticleDescription,
        },
        textField: {
          titleFieldTitle,
          textFieldSubtitle,
          textFieldDescription,
          textFieldCardTitle1,
          textFieldCardDescription1,
          textFieldCardTitle2,
          textFieldCardDescription2,
          textFieldCardTitle3,
          textFieldCardDescription3,
          textFieldCardTitle4,
          textFieldCardDescription4,
        },
        digitalApplicationDevelopmentServices: {
          digitalApplicationDevelopmentFrontend,
          digitalApplicationDevelopmentBackend,
          digitalApplicationDevelopmentProgressive,
          digitalApplicationDevelopmentSaas,
          digitalApplicationDevelopmentECommerce,
          digitalApplicationDevelopmentCustom,
          digitalApplicationDevelopmentTitle,
        },
        careerPositionSection: {
          careerPositionTitle,
          careerPositionDescription,
          careerPositionFornend,
          careerPositionBackend,
          careerPositionDevops,
          careerPositionDesigner,
          careerPositionQa,
          careerPositionProjectManagement,
          careerPositionProjectManager,
          careerPositionSupport,
          careerPositionCostumerSupport,
          careerPositionContentManager,
          careerPositionSocialMediaMarketing,
          careerPositionMarketingManager,
          careerPositionTechnicalWriter,
        },
        homePageReviewSection: {
          homePageReviewTitle,
          homePageReviewAgreedDescription,
          homePageReviewAgreedClient,
          homePageReviewAgreedPosition,
          homePageReviewBashDescription,
          homePageReviewBashClient,
          homePageReviewBashPosition,
          homePageReviewHyraDescription,
          homePageReviewHyraClient,
        },
        servicesItAugmentationSolutions: {
          customDevelopmentTitle,
          customDevelopmentDescription,
          customDevelopmentCardTitle1,
          customDevelopmentCardDescription1,
          customDevelopmentCardTitle2,
          customDevelopmentCardDescription2,
          customDevelopmentCardTitle3,
          customDevelopmentCardDescription3,
          customDevelopmentCardTitle4,
          customDevelopmentCardDescription4,
          customDevelopmentCardTitle5,
          customDevelopmentCardDescription5,
          customDevelopmentCardTitle6,
          customDevelopmentCardDescription6,
          customDevelopmentCardTitle7,
          customDevelopmentCardDescription7,
          customDevelopmentCardTitle8,
          customDevelopmentCardDescription8,
          customDevelopmentCardTitle9,
          customDevelopmentCardDescription9,
          customDevelopmentCardTitle10,
          customDevelopmentCardDescription10,
          customDevelopmentCardTitle11,
          customDevelopmentCardDescription11,
          customDevelopmentCardTitle12,
          customDevelopmentCardDescription12,
          customDevelopmentCardTitle13,
          customDevelopmentCardDescription13,
        },
        careerDescriptionSection: {
          careerDescriptionTitle,
          careerDescriptionFrontend,
          careerDescriptionBackend,
          careerDescriptionDevops,
          careerDescriptionUxdesigner,
          careerDescriptionQa,
          careerDescriptionProjectManagement,
          careerDescriptionProjectManager,
          careerDescriptionCostumerSupport,
          careerDescriptionContentManager,
          careerDescriptionSocialMediaMarketingSpecialist,
          careerDescriptionMarketingManager,
          careerDescriptionTechnicalWriter,
        },
        ourStaff: {
          ourStaffAlexName,
          ourStaffAlexJob,
          ourStaffAlexDescription,
          ourStaffMirgenName,
          ourStaffMirgenJob,
          ourStaffMirgenDescription,
          ourStaffElonaName,
          ourStaffElonaJob,
          ourStaffElonaDescription,
          ourStaffArjelName,
          ourStaffArjelJob,
          ourStaffArjelDescription,
          ourStaffDennisName,
          ourStaffDennisJob,
          ourStaffDennisDescription,
          ourStaffOthers,
          ourStaffTitle,
          ourStaffDescription,
        },
        homePageHeaderSection: {
          homeHeaderTitle,
          homeHeaderDescription,
          homeHeaderBookButton,
          homeHeaderImage: {
            localFile: {
              childImageSharp: { gatsbyImageData: Img },
            },
          },
        },
        processSection: {
          processSubtitle,
          processDescription,
          processAnalysisTitle,
          processAnalysisDescription,
          processProductTitle,
          processProductDescription,
          processDesigningTitle,
          processDesigningDescription,
          processAgileTitle,
          processAgileDescription,
        },
      },
    },
  } = props;
  const seo = {
    title: "IT Staff Augmentation Services for Your Business | Motomtech",
    metaDesc:
      "Scale your solution and save time with Motomtech’s Staff Augmentation solutions. Top talent that seamlessly integrates with your team.",
  };
  const cards = [
    {
      icon: Icon,
      title: customDevelopmentCardTitle1,
      description: customDevelopmentCardDescription1,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle2,
      description: customDevelopmentCardDescription2,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle3,
      description: customDevelopmentCardDescription3,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle4,
      description: customDevelopmentCardDescription4,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle5,
      description: customDevelopmentCardDescription5,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle6,
      description: customDevelopmentCardDescription6,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle7,
      description: customDevelopmentCardDescription7,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle8,
      description: customDevelopmentCardDescription8,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle9,
      description: customDevelopmentCardDescription9,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle10,
      description: customDevelopmentCardDescription10,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle11,
      description: customDevelopmentCardDescription11,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle12,
      description: customDevelopmentCardDescription12,
    },
    {
      icon: Icon,
      title: customDevelopmentCardTitle13,
      description: customDevelopmentCardDescription13,
    },
  ];
  const questions1 = [
    {
      title: processAnalysisTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{ __html: processAnalysisDescription }}
        />
      ),
      eventKey: 1,
    },
    {
      title: processProductTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{ __html: processProductDescription }}
        />
      ),
      eventKey: 2,
    },
  ];
  const questions2 = [
    {
      title: processDesigningTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{ __html: processDesigningDescription }}
        />
      ),
      eventKey: 3,
    },
    {
      title: processAgileTitle,
      description: (
        <p
          className="accordion-section__card-desc"
          dangerouslySetInnerHTML={{ __html: processAgileDescription }}
        />
      ),
      eventKey: 4,
    },
  ];

  const arr1 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Cost-
          <span>Effective</span>
        </p>
      ),
      description: careerDescriptionUxdesigner,
      number: "1",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Quick
          <span> Scaling:</span>
        </p>
      ),
      description: careerDescriptionQa,
      number: "2",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Flexi<span>bility:</span>
        </p>
      ),
      description: careerDescriptionProjectManagement,
      number: "3",
    },

    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Access to a<span> Diverse Skill Set:</span>
        </p>
      ),
      description: careerDescriptionProjectManager,
      number: "4",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Reduced Overhead
          <span> and Management:</span>
        </p>
      ),
      description: careerDescriptionCostumerSupport,
      number: "5",
    },
  ];
  const arr2 = [
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Potential
          <span> Onboarding Delays:</span>
        </p>
      ),
      description: careerDescriptionContentManager,
      number: "6",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Dependence on In-
          <span>house Procedures:</span>
        </p>
      ),
      description: careerDescriptionSocialMediaMarketingSpecialist,
      number: "7",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Dependence on IT
          <span> Staffing Provider:</span>
        </p>
      ),
      description: careerDescriptionMarketingManager,
      number: "8",
    },
    {
      title: (
        <p className="it-pros-cons__wrapper-content-text-title">
          Synchronizing
          <span> Workflows:</span>
        </p>
      ),
      description: careerDescriptionTechnicalWriter,
      number: "9",
    },
  ];
  const arr3 = [
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Cost-
          <span>Effectiveness:</span>
        </p>
      ),
      description: homePageReviewAgreedClient,
      number: "1",
    },

    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Quick
          <span> Scaling:</span>
        </p>
      ),
      description: homePageReviewAgreedPosition,

      number: "2",
    },

    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Flexibility:
          <span></span>
        </p>
      ),
      description: homePageReviewBashDescription,

      number: "3",
    },

    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Access
          <span> to a Diverse Skill Set:</span>
        </p>
      ),
      description: homePageReviewBashClient,

      number: "4",
    },
  ];
  const arr4 = [
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Reduced
          <span> Overhead and Management:</span>
        </p>
      ),
      description: homePageReviewBashPosition,

      number: "5",
    },
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Seamless
          <span> Integration:</span>
        </p>
      ),
      description: homePageReviewHyraDescription,

      number: "6",
    },
    {
      title: (
        <p className="it-staff__wrapper-content-text-title">
          Risk
          <span> Mitigation:</span>
        </p>
      ),
      description: homePageReviewHyraClient,
      number: "7",
    },
  ];
  const arr5 = [
    {
      title: ourStaffArjelJob,
    },
    {
      title: ourStaffArjelDescription,
    },
    {
      title: ourStaffDennisName,
    },
    {
      title: ourStaffDennisJob,
    },
    {
      title: ourStaffDennisDescription,
    },
    {
      title: ourStaffOthers,
    },
    {
      title: ourStaffTitle,
    },
    {
      title: ourStaffDescription,
    },
  ];
  return (
    <Layout seo={seo} title={seo.title}>
      <ServicesHomeComponentV2
        homeTitle={companyMissionTitle}
        homeDescription={companyMissionDescription}
        homeImage={HomeImage}
        homeButton={"Request our services"}
      />
      <WebsLogoSwiper />
      <ItExpandTeamComponent
        title={blogAlbaniaArticleTitle}
        description1={blogAlbaniaArticleAuthor}
        description2={blogAlbaniaArticleText}
        description3={blogAlbaniaArticleData}
        description4={blogAlbaniaArticleDescription}
      />
      <TimelineV1Component
        timelineTitle={titleFieldTitle}
        timelineSubtitle={textFieldSubtitle}
        timelineDescription={textFieldDescription}
        timelineTextTitle1={textFieldCardTitle1}
        timelineTextDescription1={textFieldCardDescription1}
        timelineTextTitle2={textFieldCardTitle2}
        timelineTextDescription2={textFieldCardDescription2}
        timelineTextTitle3={textFieldCardTitle3}
        timelineTextDescription3={textFieldCardDescription3}
        timelineTextTitle4={textFieldCardTitle4}
        timelineTextDescription4={textFieldCardDescription4}
      />
      <ItExpandTeamComponent
        title={digitalApplicationDevelopmentFrontend}
        description1={digitalApplicationDevelopmentBackend}
        description2={digitalApplicationDevelopmentProgressive}
        description3={digitalApplicationDevelopmentSaas}
        description4={digitalApplicationDevelopmentECommerce}
        description5={digitalApplicationDevelopmentCustom}
        description6={digitalApplicationDevelopmentTitle}
      />
      <BorderCards
        borderCardsTitle={careerPositionTitle}
        borderCardsSubitle={careerPositionDescription}
        borderCardsDescription={careerPositionFornend}
        borderCardsText1={careerPositionBackend}
        borderCardsText2={careerPositionDevops}
        borderCardsText3={careerPositionDesigner}
        borderCardsText4={careerPositionQa}
        borderCardsText5={careerPositionProjectManagement}
        borderCardsText6={careerPositionProjectManager}
        borderCardsText7={careerPositionSupport}
        borderCardsText8={careerPositionCostumerSupport}
        borderCardsText9={careerPositionContentManager}
        borderCardsText10={careerPositionSocialMediaMarketing}
        borderCardsText11={careerPositionMarketingManager}
        borderCardsText12={careerPositionTechnicalWriter}
      />
      <ItStaffComponent
        title={homePageReviewTitle}
        description={homePageReviewAgreedDescription}
        arr1={arr3}
        arr2={arr4}
      />
      <CardsComponent
        title={customDevelopmentTitle}
        description={customDevelopmentDescription}
        cards={cards}
      />
      <div style={{ position: "relative", zIndex: "3" }}>
        <ItProsConsComponent
          title={careerDescriptionTitle}
          description={careerDescriptionFrontend}
          text1={careerDescriptionBackend}
          text2={careerDescriptionDevops}
          arr1={arr1}
          arr2={arr2}
        />
      </div>
      <ItExpectComponent
        arr={arr5}
        title={ourStaffAlexName}
        subtilte={ourStaffAlexJob}
        text1={ourStaffAlexDescription}
        text2={ourStaffMirgenName}
        text3={ourStaffMirgenJob}
        text4={ourStaffMirgenDescription}
        text5={ourStaffElonaName}
        text6={ourStaffElonaJob}
        text7={ourStaffElonaDescription}
        text8={ourStaffArjelName}
      />
      <TechStackComponent />
      <CustomDevelopmentCoreIndustries
        image={Img}
        title={homeHeaderTitle}
        text1={homeHeaderDescription}
        text2={homeHeaderBookButton}
      />
      <div style={{ position: "relative" }}>
        <CustomDevelopmentVideoComponent />
      </div>
      <AccordionComponent
        arr1={questions1}
        arr2={questions2}
        title={processSubtitle}
        subtitle={processDescription}
      />
      <div
        style={{
          background:
            "linear-gradient(108.34deg, #09062D -2.63%, #36498C 100%)",
        }}
      >
        <BookCallServiceComponent
          bookCallServiceTitle={
            "Staff Augmentation with Motomtech is a call away."
          }
          bookCallServiceDescription={"Stop wasting time."}
          isFirstService={true}
          isSecondService={false}
        />
      </div>
      <FooterComponent />
    </Layout>
  );
};

export default ServiceIT;

export const query = graphql`
  {
    wpPage(slug: { eq: "serviceit" }) {
      seo {
        title
        metaDesc
      }
      companyMissionSection {
        companyMissionTitle
        companyMissionDescription
        companyMissionImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      blogAlbaniaArticle {
        blogAlbaniaArticleTitle
        blogAlbaniaArticleAuthor
        blogAlbaniaArticleText
        blogAlbaniaArticleData
        blogAlbaniaArticleDescription
      }
      textField {
        titleFieldTitle
        textFieldSubtitle
        textFieldDescription
        textFieldCardTitle1
        textFieldCardDescription1
        textFieldCardTitle2
        textFieldCardDescription2
        textFieldCardTitle3
        textFieldCardDescription3
        textFieldCardTitle4
        textFieldCardDescription4
      }
      digitalApplicationDevelopmentServices {
        digitalApplicationDevelopmentFrontend
        digitalApplicationDevelopmentBackend
        digitalApplicationDevelopmentProgressive
        digitalApplicationDevelopmentSaas
        digitalApplicationDevelopmentECommerce
        digitalApplicationDevelopmentCustom
        digitalApplicationDevelopmentTitle
      }
      careerPositionSection {
        careerPositionTitle
        careerPositionDescription
        careerPositionFornend
        careerPositionBackend
        careerPositionDevops
        careerPositionDesigner
        careerPositionQa
        careerPositionProjectManagement
        careerPositionProjectManager
        careerPositionSupport
        careerPositionCostumerSupport
        careerPositionContentManager
        careerPositionSocialMediaMarketing
        careerPositionMarketingManager
        careerPositionTechnicalWriter
      }
      homePageReviewSection {
        homePageReviewTitle
        homePageReviewAgreedDescription
        homePageReviewAgreedClient
        homePageReviewAgreedPosition
        homePageReviewBashDescription
        homePageReviewBashClient
        homePageReviewBashPosition
        homePageReviewHyraDescription
        homePageReviewHyraClient
      }
      servicesItAugmentationSolutions {
        customDevelopmentTitle
        customDevelopmentDescription
        customDevelopmentCardTitle1
        customDevelopmentCardDescription1
        customDevelopmentCardTitle2
        customDevelopmentCardDescription2
        customDevelopmentCardTitle3
        customDevelopmentCardDescription3
        customDevelopmentCardTitle4
        customDevelopmentCardDescription4
        customDevelopmentCardTitle5
        customDevelopmentCardDescription5
        customDevelopmentCardTitle6
        customDevelopmentCardDescription6
        customDevelopmentCardTitle7
        customDevelopmentCardDescription7
        customDevelopmentCardTitle8
        customDevelopmentCardDescription8
        customDevelopmentCardTitle9
        customDevelopmentCardDescription9
        customDevelopmentCardTitle10
        customDevelopmentCardDescription10
        customDevelopmentCardTitle11
        customDevelopmentCardDescription11
        customDevelopmentCardTitle12
        customDevelopmentCardDescription12
        customDevelopmentCardTitle13
        customDevelopmentCardDescription13
      }
      careerDescriptionSection {
        careerDescriptionTitle
        careerDescriptionFrontend
        careerDescriptionBackend
        careerDescriptionDevops
        careerDescriptionUxdesigner
        careerDescriptionQa
        careerDescriptionProjectManagement
        careerDescriptionProjectManager
        careerDescriptionCostumerSupport
        careerDescriptionContentManager
        careerDescriptionSocialMediaMarketingSpecialist
        careerDescriptionMarketingManager
        careerDescriptionTechnicalWriter
      }
      ourStaff {
        ourStaffAlexName
        ourStaffAlexJob
        ourStaffAlexDescription
        ourStaffMirgenName
        ourStaffMirgenJob
        ourStaffMirgenDescription
        ourStaffElonaName
        ourStaffElonaJob
        ourStaffElonaDescription
        ourStaffArjelName
        ourStaffArjelJob
        ourStaffArjelDescription
        ourStaffDennisName
        ourStaffDennisJob
        ourStaffDennisDescription
        ourStaffOthers
        ourStaffTitle
        ourStaffDescription
      }
      homePageHeaderSection {
        homeHeaderTitle
        homeHeaderDescription
        homeHeaderBookButton
        homeHeaderImage {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
      processSection {
        processSubtitle
        processDescription
        processAnalysisTitle
        processAnalysisDescription
        processProductTitle
        processProductDescription
        processDesigningTitle
        processDesigningDescription
        processAgileTitle
        processAgileDescription
        processLaunchingTitle
        processLaunchingDescription
      }
    }
  }
`;

import React from 'react';

const ItExpectComponent = props => {
  const {
    arr,
    title,
    subtitle,
    text1,
    text2,
    text3,
    text4,
    text5,
    text6,
    text7,
    text8,
  } = props;
  return (
    <>
      <div className='it-expect-section'>
        <p
          className='it-expect-section-title'
          dangerouslySetInnerHTML={{ __html: title }}
        />
        <p
          className='it-expect-section-subtitle'
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
        <div className='full-container'>
          <div className='border-container'>
            <div className=' d-flex border-row justify-content-between '>
              <div className='it-expect-section-main-container border-card'>
                <div className='d-flex'>
                  <div className='icon'>
                    1<div className='icon-position'></div>
                  </div>
                  <div className='it-expect-section-main-body'>
                    <h3 className='it-expect-section-main-container-title'>
                      Talent Pool:
                    </h3>{' '}
                    <p
                      className='it-expect-section-main-container-description'
                      dangerouslySetInnerHTML={{ __html: text1 }}
                    />
                  </div>
                </div>
              </div>
              <div className='it-expect-section-main-container border-top-mobile position-relative m-top-72'>
                <div className='d-flex'>
                  <div className='icon'>
                    2<div className='icon-position'></div>
                  </div>
                  <div className='it-expect-section-main-body'>
                    {' '}
                    <h3 className='it-expect-section-main-container-title'>
                      Development Models:
                    </h3>
                    <p
                      className='it-expect-section-main-container-description'
                      dangerouslySetInnerHTML={{ __html: text2 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex  border-row justify-content-between margin-top border-x position-relative'>
              <div className='it-expect-section-main-container'>
                <div className='d-flex'>
                  <div className='icon'>
                    3<div className='icon-position'></div>
                  </div>
                  <div className='it-expect-section-main-body'>
                    {' '}
                    <h3 className='it-expect-section-main-container-title'>
                      Proficiency with Technology:
                    </h3>
                    <p
                      className='it-expect-section-main-container-description'
                      dangerouslySetInnerHTML={{ __html: text3 }}
                    />
                  </div>
                </div>
              </div>
              <div className='it-expect-section-main-container border-top-mobile position-relative m-top-72'>
                <div className='d-flex'>
                  <div className='icon'>
                    4<div className='icon-position'></div>
                  </div>
                  <div className='it-expect-section-main-body'>
                    {' '}
                    <h3 className='it-expect-section-main-container-title'>
                      English Proficiency:
                    </h3>
                    <p
                      className='it-expect-section-main-container-description'
                      dangerouslySetInnerHTML={{ __html: text4 }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex border-row  justify-content-between margin-top border-x position-relative'>
              <div className='it-expect-section-main-container'>
                <div className='d-flex'>
                  <div className='icon'>
                    5<div className='icon-position'></div>
                  </div>
                  <div className='it-expect-section-main-body'>
                    <h3 className='it-expect-section-main-container-title'>
                      Security Protocols:
                    </h3>
                    <p
                      className='it-expect-section-main-container-description'
                      dangerouslySetInnerHTML={{ __html: text5 }}
                    />
                  </div>
                </div>
              </div>
              <div className='it-expect-section-main-container border-top-mobile position-relative m-top-72'>
                <div className='d-flex'>
                  <div className='icon'>
                    6<div className='icon-position'></div>
                  </div>
                  <div className='it-expect-section-main-body'>
                    <h3 className='it-expect-section-main-container-title'>
                      Time Zone Compatibility:
                    </h3>
                    <p
                      className='it-expect-section-main-container-description'
                      dangerouslySetInnerHTML={{ __html: text6 }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <p
          className='it-expect-section-description'
          dangerouslySetInnerHTML={{ __html: text7 }}
        />
      </div>

      <div className='it-expect-banner'>
        <p
          className='it-expect-banner-title'
          dangerouslySetInnerHTML={{ __html: text8 }}
        />
      </div>

      <div className='it-expect-cards'>
        <div className='full-container'>
          <div className='it-expect-cards-wrapper'>
            <div className='row'>
              {arr.map((item, i) => (
                <div className='col-md-6'>
                  <div className='it-expect-cards-wrapper-content' key={i}>
                    <p
                      className='it-expect-cards-wrapper-content-title'
                      dangerouslySetInnerHTML={{ __html: item.title }}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ItExpectComponent;
